<template>
  <div>
    <SPINNER v-if="state_loading" />
    <v-app class="bg-color" >
      <router-view />
    </v-app>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SPINNER from "./components/global/Spinner.vue";

export default {
  name: "App",
  components: { SPINNER },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      state_loading: "_stateLoading",
    }),
  },
};
</script>
<style lang="scss">
.bg-color {
  background-color: #000000 !important;
}
@import "./assets/sass/style.scss";
</style>
