import { CURRTET_USER } from "@/global";
const isAuthenticatedGuard = async (to, from, next) => {
  return new Promise(() => {
    if (CURRTET_USER?.roleId == 4) return next();
    else if (CURRTET_USER?.roleId == 1) return next({ path: "/miembro-votante/votar" });
    else if (CURRTET_USER?.roleId == 5) return next({ path: "/miembro-votante/registro" });
    else if (CURRTET_USER?.roleId == 2) return next();
    else if (CURRTET_USER?.roleId == 3)
      return next({ path: "/comite-tecnico/panel-comite-tecnico" });
    else next({ path: "/inicio" });
    // else next({ path: "/postulantes/registro-canal" });
  });
};
export default isAuthenticatedGuard;